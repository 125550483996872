import React from "react";
import Helmet from "react-helmet";

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Select Home Warranty</title>
      </Helmet>

      <div>Home page</div>
    </>
  )
}

export default IndexPage
